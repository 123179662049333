<template>
    <div class="cover" :class="{'is-main-page': $route.name == 'MainPage', 'is-offline-page': serverOffline || pageClosed}">
        <div class="cover__container app__container">
            <slot>
                <template v-if='!serverOffline && !pageClosed'>
                    <h1>{{ $t('name') }}</h1>
                    <section v-html='trans(settings, "cover")' />
                    <div class="special-menu-buttons">
                        <router-link :to='{name: "Menu"}' class="button is-red">
                            <span>{{ $t('order.button') }}</span>
                        </router-link>
                        <router-link v-for="specialMenu in specialMenus" :key="`specialMenu${specialMenu.id}`" :to='{name: "SpecialMenu", params: { slug: specialMenu.slug }}' class="button is-red">
                            <span>{{ specialMenu.button }}</span>
                        </router-link>
                        <a href="tel:+48502756666" class="button is-red">
                            <span>{{ $t('order.phone') }}</span>
                        </a>
                    </div>
                    <div>
                        <scroll-down-arrow :onClick='() => { scrollTo("#seeMenu") }' />
                    </div>
                </template>
            </slot>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { trans } from '../../i18n/functions'
export default {
    components: {
        ScrollDownArrow: () => import('../others/ScrollDownArrow'),
    },
    props: ['pageClosed'],
    computed: {
        ...mapGetters({
            specialMenus: 'specialMenus',
            settings: 'settings',
            serverOffline: 'serverOffline',
        })
    },
    methods: {
        trans,
        scrollTo(nodeId) {
            document.querySelector(nodeId).scrollIntoView({behavior: "smooth"})
        }
    }
}
</script>

<style lang="scss">
@import '../../assets/css/webp.scss';

.cover {
    display: flex;
    align-items: center;
    justify-content: center;
    @include bg-url('../../assets/img/cover.jpg', false, '../../assets/img/cover.webp', false, linear-gradient(to top, #{var(--bg-black-layer)}, #{var(--bg-black-layer)}));
    background-size: cover;
    background-position: center;

    padding: var(--nav-height) 0 calc(var(--nav-height)/2);

    color: var(--white);

    &.is-main-page {
        min-height: 85vh;
        padding-bottom: 0;
    }

    &.is-offline-page {
        width: 100vw !important;
        height: 100vh !important;
        box-sizing: border-box;
    }

    section {
        max-width: 720px;
        margin: 0 auto;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.6px;
    }
}
</style>
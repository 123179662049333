import { getUberMenu, } from '../../api/adminApi'
import { getUberStoreStatus } from '../../api/api'
// import { getUberMenu, getUberDeliveryMenu } from '../../api/adminApi'
// import { getUberStoreStatus, getUberDeliveryStoreStatus } from '../../api/api'

const state = {
    uberMenu: null,
    uberStoreStatus: null,
    uberStoreOfflineReason: null,
    uberDeliveryMenu: null,
    uberDeliveryStoreStatus: null,
    uberDeliveryStoreOfflineReason: null,
}

const formatUberMenu = (menu) => {
    if(!menu.categories) return []
    let dishes = []
    menu.categories.forEach(category => {
        let categoryTitle = category.title.translations.en
        category.entities.forEach(item => {
            let dish = menu.items.find(i => i.id === item.id)
            let dishTitle = dish.title.translations.en
            dishes.push({
                id: dish.id,
                title: `${categoryTitle}: ${dishTitle}`
            })
            if (dish.modifier_group_ids !== undefined && dish.modifier_group_ids.ids && dish.modifier_group_ids.ids.length > 0) {
                    dish.modifier_group_ids.ids.forEach(mod => {
                        let modGroup = menu.modifier_groups.find(group => group.id === mod)
                        let modGroupTitle = modGroup.title.translations.en
                        modGroup.modifier_options.forEach(opt => {
                            let modOptions = menu.items.find(i => i.id === opt.id)
                            dishes.push({
                                id: modOptions.id,
                                title: `${categoryTitle}: ${dishTitle}: ${modGroupTitle}: ${modOptions.title.translations.en}`
                            })
                        })
                    })
                }
        })
    });
    dishes = dishes.sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0))
    dishes.unshift({
        id: '-',
        title: '-',
    })
    return dishes;
}

const getters = {
    getUberMenu: state => {
        if (state.uberMenu === null) {
            return undefined
        }
        return formatUberMenu(state.uberMenu)
    },
    getUberDeliveryMenu: state => {
        if (state.uberDeliveryMenu === null) {
            return undefined
        }
        return formatUberMenu(state.uberDeliveryMenu)
    },
    uberMenuLoaded: state => state.uberMenu !== null && state.uberDeliveryMenu !== null,
    uberStoreStatus: state => ({status: state.uberStoreStatus, reason: state.uberStoreOfflineReason}),
    uberDeliveryStoreStatus: state => ({status: state.uberDeliveryStoreStatus, reason: state.uberDeliveryStoreOfflineReason}),
}

const mutations = {
    saveUberMenu(state, payload) {
        state.uberMenu = payload
    },
    saveUberDeliveryMenu(state, payload) {
        state.uberDeliveryMenu = payload
    },
    setUberStatus(state, payload) {
        state.uberStoreStatus = payload.status
        if (payload.offlineReason !== undefined) {
            state.uberStoreOfflineReason = payload.offlineReason
        }
    },
    setUberDeliveryStatus(state, payload) {
        state.uberDeliveryStoreStatus = payload.status
        if (payload.offlineReason !== undefined) {
            state.uberDeliveryStoreOfflineReason = payload.offlineReason
        }
    },
}

const actions = {
    downloadUberMenu({ commit, getters }) {
        if (!getters.uberMenuLoaded) {
            return getUberMenu()
                .then(res => {
                    commit('saveUberMenu', res.data.uberMenu)
                })
        }
    },
    downloadUberDeliveryMenu() {},
    // downloadUberDeliveryMenu({ commit, getters }) {
    //     if (!getters.uberMenuLoaded) {
    //         return getUberDeliveryMenu()
    //             .then(res => {
    //                 commit('saveUberDeliveryMenu', res.data.uberMenu)
    //             })
    //     }
    // },
    getUberStatus({ commit }) {
        getUberStoreStatus()
            .then(res => {
                commit('setUberStatus', res.data)
            })
    },
    getUberDeliveryStatus() {},
    // getUberDeliveryStatus({ commit }) {
    //     getUberDeliveryStoreStatus()
    //         .then(res => {
    //             commit('setUberDeliveryStatus', res.data)
    //         })
    // },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}